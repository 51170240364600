import i18n from 'i18next'
import { vsprintf } from 'sprintf-js'

const { userAgent } = window.navigator

/**
 * Detects type device
 */

export const isTablet = /iPad|Android(?!.*Mobile)|Tablet|Silk/i.test(userAgent)
export const isMobile = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  userAgent
)

/**
 * Converts object to array
 *
 * @param {Object} object
 * @returns {Array}
 */
export const objectToArray = (object) => {
  if (!object || Object.keys(object).length === 0) {
    return []
  }
  return Object.keys(object).map((key) => object[key])
}

/**
 * Converts and array of objects to object using passed property as key for each object.
 *
 * @param {Array} array
 * @param {string} useAsProperty
 * @returns {Object}
 */
export const arrayToObject = (array, useAsProperty = 'id') => {
  let object = {}
  array.map((item) => (object[item[useAsProperty]] = item))
  return object
}

/**
 * Checks if nested property exists in object
 * Pass params like: checkNestedProp(object, 'key', 'key2', 'key3') etc
 *
 * @param {Object} obj
 * @param {string} property
 * @returns {bool}
 */
export const checkNestedProp = (obj, property, ...rest) => {
  if (obj === undefined) return false
  if (rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, property)) return true
  return this.checkNestedProp(obj[property], ...rest)
}

/**
 * @description ### Returns Go / Lua like responses(data, err)
 * when used with await
 *
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.all([req1, req2, req3])
 * - Example response [ [data1, data2, data3], undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.race([req1, req2, req3])
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 * @param {Promise} promise
 * @returns {Promise} [ data, undefined ]
 * @returns {Promise} [ undefined, Error ]
 */
// TODO: move to async.ts
export const to = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]))
}

/**
 *
 * @param {Array} stringArray
 * @param {bool} formatCase
 * @returns {string}
 */
export const joinStringArray = (stringArray, formatCase = true) => {
  const array = stringArray.filter((item) => typeof item === 'string' && item.length > 0)

  const length = array.length
  let string = ''
  array.map((item, index) => {
    if (index < length - 2) {
      string += item + ', '
    } else if (index === length - 2) {
      string += item + ' ' + i18n.t('words.and') + ' '
    } else {
      string += item
    }
    return null
  })
  if (formatCase) {
    string = string.toLowerCase()
    string = string.charAt(0).toUpperCase() + string.slice(1)
  }
  return string
}

/**
 * Sorts errors into object by field name
 *
 * @param {Object} err
 * @returns {Promise<Array>}
 */
export const sortAndTranslateErrors = (err) =>
  new Promise((resolve, reject) => {
    const { inner } = err

    let sortedErrors = {}

    inner.forEach((err) => {
      const { path, errors } = err
      if (!sortedErrors[path]) sortedErrors[path] = []
      sortedErrors[path].push(
        joinStringArray(
          errors.map((error) =>
            typeof error === 'string'
              ? i18n.t(`errors.${error}`)
              : i18n.t(`errors.${error.key}`, { count: error.count })
          )
        )
      )
    })

    let joinedErrors = {}
    Object.keys(sortedErrors).forEach((key) => {
      joinedErrors[key] = joinStringArray(sortedErrors[key])
    })

    reject(joinedErrors)
  })

/**
 * Validates that the input value is an e-mail
 *
 * @param {string} email
 * @returns {Bool}
 */
export const validateEmail = (email) => {
  if (!email) return true
  else {
    // eslint-disable-next-line
    let re =
      // eslint-disable-next-line
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return re.test(String(email).toLowerCase())
  }
}

/**
 * Check's if click was inside the boundaries of any of the items in the array
 *
 * @param {array} array
 * @param {object} event
 * @returns {Bool}
 */
export const didClickInsideArray = (array, event) => {
  for (let i = 0; i < array.length; i++) {
    let element = array[i]
    if (didClickInside(element, event)) return true
  }
  return false
}

export const getElementTopLeft = (el) => {
  if (!el) return { top: 0, left: 0 }

  const rect = el.getBoundingClientRect()

  return { top: rect.top, left: rect.left }
}

export const getElementBottomRight = (el) => {
  if (!el) return { bottom: 0, right: 0 }

  const rect = el.getBoundingClientRect()

  return { bottom: rect.bottom, right: rect.right }
}

/**
 *
 * @param {*} el
 * @returns
 */
export const getElementRect = (el) => {
  return { ...getElementTopLeft(el), ...getElementBottomRight(el) }
}

export const getElementDimensions = (el) => {
  if (!el) return { height: 0, width: 0 }
  let h = el.offsetHeight
  let w = el.offsetWidth
  return { height: h, width: w }
}

/**
 * Check's if click was inside the boundaries of element
 *
 * @param {object} refElement
 * @param {object} event
 * @returns {Bool}
 */
export const didClickInside = (refElement, event) => {
  if (!refElement) return false
  try {
    let boundary = refElement.getBoundingClientRect()
    return (
      event.clientX >= boundary.x &&
      event.clientX <= boundary.x + boundary.width &&
      event.clientY >= boundary.y &&
      event.clientY <= boundary.y + boundary.height
    )
  } catch (e) {
    return false
  }
}

/**
 * Validates if variable is set
 * @param {any} variable
 * @returns {Bool}
 */
export const isSet = (variable) => {
  return !(variable === null || variable === undefined || variable === '')
}

/**
 * Formats a string value into a phone number
 * with support for country codes (+46)
 * @param {string} phone
 * @returns {string}
 */
export const formatPhoneNumber = (phone) => {
  let raw = phone.replace(/\s/g, '')

  if (raw.includes('-')) raw = raw.replace('-', '')
  if (/^\d{9}$/.test(raw)) {
    let numbers = raw.split('')
    numbers.splice(0, 1)
    return vsprintf('+46 %s%s %s%s %s%s %s%s', numbers)
  } else if (/^\d{10}$/.test(raw)) {
    let numbers = raw.split('')
    numbers.splice(0, 1)
    return vsprintf('+46 %s%s %s%s%s %s%s %s%s', numbers)
  } else if (/^[+]\d{11}$/.test(raw)) {
    let numbers = raw.split('')
    numbers.splice(0, 1)
    return vsprintf('+%s%s %s%s %s%s%s %s%s %s%s', numbers)
  }

  return phone
}

/**
 * Formats event input value to phone no format
 * @param {object} event
 */
export const autoFormatNumber = (event) => {
  let value = event.target.value
  value = formatPhoneNumber(value)
  event.target.value = value
}

/**
 *
 * @param {int} price Price in cents
 * @param {string} currency
 * @param {bool} trailingZeros
 */
export const priceToLocal = (price = 0, currency = 'SEK', trailingZeros = false) => {
  if (currency) {
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency,
      minimumFractionDigits: trailingZeros === true ? undefined : price % 1 === 0 ? 0 : 2,
    }).format(price / 100)
  } else {
    return new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: trailingZeros === true ? undefined : price % 1 === 0 ? 0 : 2,
    }).format(price / 100)
  }
}

/**
 * Returns language-insensitive price
 * Example output:
 * "330:-" for "SEK" currency
 * "330,-" for "NOK|DKK" currency
 * "330£" for "GBP" currency
 * "€330" for "USD" currency
 * "₣330" for "EUR" currency
 * @param {number} price Price in cents
 * @param {string} currency
 * @param {boolean} trailingZeros
 */
export const formatPriceWithCurrencySymbol = (
  price = 0,
  currency = 'SEK',
  trailingZeros = false
) => {
  if (currency) {
    let formattedPrice = Intl.NumberFormat('en', {
      style: 'currency',
      currency,
      minimumFractionDigits: trailingZeros === true ? undefined : price % 1 === 0 ? 0 : 2,
    }).format(price / 100)
    if (formattedPrice.search(`DKK|NOK`) !== -1) {
      const regex = new RegExp(`DKK|NOK`, 'g')
      formattedPrice = `${formattedPrice.replace(regex, '')},-`
    }

    if (formattedPrice.search(`SEK`) !== -1) {
      const regex = new RegExp(`SEK`, 'g')
      formattedPrice = `${formattedPrice.replace(regex, '')}:-`
    }

    if (formattedPrice.search(`CHF`) !== -1) {
      const regex = new RegExp(`CHF`, 'g')
      formattedPrice = `${formattedPrice.replace(/\s/g, '').replace(regex, '₣')}`
    }

    if (formattedPrice.search(`£`) !== -1) {
      const regex = new RegExp(`£`, 'g')
      formattedPrice = `${formattedPrice.replace(regex, '')}£`
    }

    if (formattedPrice.search(`$`) !== -1 && currency === 'USD') {
      const regex = new RegExp('\\$', 'g')
      formattedPrice = `${formattedPrice.replace(regex, '')}$`
    }

    return formattedPrice.trim()
  }

  return new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: trailingZeros === true ? undefined : price % 1 === 0 ? 0 : 2,
  }).format(price / 100)
}

export const currencyCodeInCurrentLanguage = (currency = 'SEK') => {
  return new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
    signDisplay: 'never',
  })
    .formatToParts(1)
    .find((c) => c.type === 'currency')?.value
}

/**
 * Converts number to local string
 *
 * @param {number} value
 * @returns {string}
 */
export const numberToLocalFormat = (value = 0) => {
  return new Intl.NumberFormat(i18n.language).format(value) || ''
}

/**
 * Reverses the Intl.NumberFormat to int again
 *
 * @param {string} val
 * @param {string} locale
 * @returns {nunmber}
 */
export const reverseLocalFormat = (val, locale = i18n.language) => {
  const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
  const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
  let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
  reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
  return Number.isNaN(reversedVal) ? null : reversedVal
}

/**
 * Gets next char in ASCI table
 *
 * @param {string} char
 * @returns {string}
 */
export const getNextChar = (char, plus) => {
  return String.fromCharCode(char.charCodeAt(0) + (plus || 1))
}

/* Change the first letter of the string to upper case
 *
 * @param {String} string
 * @returns {String} capitalized string
 */
export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const onEnter = (inputs, func) => {
  if (!Array.isArray(inputs)) {
    inputs = [inputs]
  }

  inputs.forEach((input) => {
    input.addEventListener('keyup', (event) => {
      event.preventDefault()
      if (event.key === 'Enter') func()
    })
  })
}

export const clearOnEnter = (inputs, func) => {
  if (!Array.isArray(inputs)) {
    inputs = [inputs]
  }

  inputs.forEach((input) => {
    input.removeEventListener('keyup', func)
  })
}

export const deepClone = (object) => {
  return JSON.parse(JSON.stringify(object))
}

export const arraysAreEqual = (a, b) => {
  if ((a === null && b === null) || (a === undefined && b === undefined)) return true
  if ((isSet(a) && !isSet(b)) || (!isSet(a) && isSet(b))) {
    return false
  }
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false
    }
  }
  return true
}

export const objectValueFromDotNotation = (object, key) => {
  try {
    if (key && key.toString().substring(0, 1) === '.') {
      key = key.toString().substring(1)
    }
    return key.split('.').reduce((o, i) => o[i], object)
  } catch {
    return null
  }
}

export const isGolfId = (string) => {
  if (!string) return true
  const re = /^\d{6,8}[-|(\s)]{0,1}\d{3}$/
  return re.test(String(string))
}

export const isCdhNumber = (string) => {
  if (!string) return true
  return string.length >= 8 && string.length <= 10
}

export const isExactCdhNumber = (string) => {
  if (!string) return false
  const isNumeric = /^\d+$/
  return (string.length === 8 || string.length === 10) && isNumeric.test(string)
}

export const isCdhNumberFormat = (string) => {
  if (!string) return false
  const isAllDigits = /^[0-9]+$/.test(string)
  return isAllDigits && string.length >= 8 && string.length <= 10
}

export const isAllowedGolfIDFormat = (string) => {
  const re = /^\d{1,6}(?:-\d*)?$/
  return re.test(String(string))
}

export const isAllowedPhoneNumber = (string) => {
  const re = /^\+\s*[0-9\s\b]*$/
  return re.test(String(string))
}

export const isBeginningOfGolfId = (string) => {
  if (!string) return false
  const re = /^\d{6,8}$/
  return re.test(String(string))
}

export const isJson = (string) => {
  try {
    JSON.parse(string)
  } catch (error) {
    return false
  }
  return true
}

export const isElementInViewport = (el) => {
  if (!el || !el?.getBoundingClientRect) return null

  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  )
}

/**
 * Self-invoking async function
 * Nice to use in useEffects for example
 *
 * @param {function} fn
 * @param {function} [catcher]
 */
// TODO: move to async.ts
export const selfAsync = (fn, catcher = console.warn) => {
  return (async () => fn())().catch(catcher)
}

/**
 * Converts a number to decimal percent.
 * Ex: 98 -> 0.98
 *
 * @memberof Utilities
 * @param {string|number} number
 * @returns {number}
 */
export const numberToDecimalPercent = (number) => {
  const parsedNumber = parseInt(number)
  let percentage = (parsedNumber / 100).toFixed(2)
  return parseFloat(percentage)
}

/**
 * Converts a decimal percent to number.
 * Ex: 0.75 -> 75
 *
 * @memberof Utilities
 * @param {string|number} percent
 * @returns {number}
 */
export const decimalPercentToNumber = (percent) => {
  const parsedPercent = parseFloat(percent)
  let number = (parsedPercent * 100).toFixed(0)
  return parseInt(number)
}

/**
 * Converts float to cents value
 *
 * @memberof Utilities
 * @param {number} float
 * @returns {number}
 */
export const floatToCents = (float) => {
  return parseInt(parseFloat(float * 100).toFixed(0))
}

/**
 * Converts cents to float
 *
 * @memberof Utilities
 * @param {number} cents
 * @returns {number}
 */
export const centsToFloat = (cents) => {
  return parseFloat(cents / 100)
}

/**
 * Chunk array into specific size
 *
 * @param {Array} arr
 * @param {number} size
 * @returns
 */
export const arrayChunk = (arr, size) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
}

/**
 * Removes falsly and undefined keys from object, only one level
 *
 * @param {*} object
 * @returns {Object}
 */
export const deleteNullKeysShallow = (object) => {
  let newObject = { ...object }
  Object.keys(newObject).forEach(
    (key) => !newObject[key] && newObject[key] !== undefined && delete newObject[key]
  )

  return newObject
}

/**
 * Convert hex color to rgb
 *
 * @param {string} hex
 * @returns
 */
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : null
}

/**
 * Convert hex color to rgba
 *
 * @param {string} hex
 * @returns
 */
export const hexToRgba = (hex, a = 1) => {
  return `${hexToRgb(hex)}, ${a}`
}

export const webColorStringToColorObj = (colorString) => {
  const colorObj = {
    name: colorString,
    hex: '#000000',
  }

  switch (colorString) {
    case 'light':
      colorObj.hex = '#A8A8A8'
      break
    case 'white':
      colorObj.hex = '#ffffff'
      break
    case 'black':
      colorObj.hex = '#000000'
      break
    case 'dark':
      colorObj.hex = '#585858'
      break
    default:
      break
  }
  colorObj.rgba = `rgba(${hexToRgba(colorObj.hex)})`
  return colorObj
}

/**
 * Shades(darken, lighten) color by amount
 *
 * @param {string} col
 * @param {number} amt
 * @returns
 */
export const colorShade = (col, amt) => {
  col = col.replace(/^#/, '')
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

  let [r, g, b] = col.match(/.{2}/g)
  ;[r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt]

  r = Math.max(Math.min(255, r), 0).toString(16)
  g = Math.max(Math.min(255, g), 0).toString(16)
  b = Math.max(Math.min(255, b), 0).toString(16)

  const rr = (r.length < 2 ? '0' : '') + r
  const gg = (g.length < 2 ? '0' : '') + g
  const bb = (b.length < 2 ? '0' : '') + b

  return `#${rr}${gg}${bb}`
}

/**
 * Make value observable
 *
 * @param {*} target
 * @returns
 */
export const makeObservable = (target) => {
  let listeners = []
  let value = target

  const get = () => {
    return value
  }

  const set = (newValue) => {
    if (value === newValue) return
    value = newValue
    listeners.forEach((l) => l(value))
  }

  // Used inside useEffect
  const subscribe = (listenerFunc) => {
    listeners.push(listenerFunc)
    return () => unsubscribe(listenerFunc)
  }

  const unsubscribe = (listenerFunc) => {
    listeners = listeners.filter((l) => l !== listenerFunc)
  }

  return {
    get,
    set,
    subscribe,
  }
}

/**
 * Merge first name and last name to single string
 *
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string}
 */
export const mergeFirstLastName = (firstName, lastName) => {
  if (!firstName && lastName) return lastName
  if (!lastName && firstName) return firstName
  if (lastName && firstName) return `${firstName} ${lastName}`
  return ''
}

/**
 * Get all object values, even nested and return as array
 *
 * @param {Object} obj
 * @returns {Array}
 */
export const getAllValuesFromMultiObjectAsArray = (obj) => {
  if (obj && typeof obj === 'object' && obj !== null) {
    return Object.values(obj).map(getAllValuesFromMultiObjectAsArray).flat()
  } else {
    return obj
  }
}

/**
 * Checks if string is a number and only a valid number, eg does not contain text
 * Does return true for leading and trailing space tho, so beware
 *
 * @param {string} str
 */
export const isStringNumber = (str) => {
  if (typeof str != 'string') return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

/**
 * Sort an array in custom order
 *
 * @param {Array} array
 * @param {string} order
 * @param {number} key
 */
export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    let A = a
    const B = b
    if (key) {
      A = a[key]
      b = b[key]
    }
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

/**
 * Checks if is element
 *
 * @param {*} element
 * @returns
 */
export const isElement = (element) => {
  if (!element) return false
  return element instanceof Element || element instanceof HTMLDocument
}
