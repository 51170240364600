import partnerPortalEn from '../langs/partner-portal/en.json'
import partnerPortalSv from '../langs/partner-portal/sv.json'
import partnerPortalDe from '../langs/partner-portal/de.json'
import partnerPortalDa from '../langs/partner-portal/da.json'
import i18next from 'i18next'
import { I18nextNamespaces, LanguageKeys } from '../constants/enums'

export const addPartnerPortalBundles = (): void => {
  i18next.addResourceBundle(
    LanguageKeys.EN,
    I18nextNamespaces.DEFAULT,
    partnerPortalEn,
    true,
    false
  )
  i18next.addResourceBundle(
    LanguageKeys.SV,
    I18nextNamespaces.DEFAULT,
    partnerPortalSv,
    true,
    false
  )
  i18next.addResourceBundle(
    LanguageKeys.DE,
    I18nextNamespaces.DEFAULT,
    partnerPortalDe,
    true,
    false
  )
  i18next.addResourceBundle(
    LanguageKeys.DA,
    I18nextNamespaces.DEFAULT,
    partnerPortalDa,
    true,
    false
  )
}
