import { CONSTANTS as REDUX_ACTIONS } from '@sweetspot/apps/partner-portal/store/constants'
import produce from 'immer'
import initialState from './initialState'

const club = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.CLUB.SET_CLUB:
      return produce(state, (newState) => {
        newState.club = action?.club || null
      })
    case REDUX_ACTIONS.CLUB.SET_CLUB_WEB_BOOKING_SETTINGS:
      return produce(state, (newState) => {
        newState.clubWebBookingSettings = action?.payload || null
      })
    default:
      return state
  }
}

export default club
