import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Fetches the users partnerships
 *
 * @returns {Promise}
 */
export const getPartnershipList = (query) => {
  const url = `${getBaseUrlPlatform()}/crm/partnerships?${query}`

  return request(url, { method: 'GET', accept: 'application/ld+json' })
}

/**
 * Fetches a single partnership
 *
 * @returns {Promise}
 */
export const getPartnership = (id) => {
  const url = `${getBaseUrlPlatform()}/partnerships/${id}`

  return request(url, { method: 'GET' })
}
