export const CONSTANTS = {
  PARTNERSHIP: {
    FETCHING: 'PARTNERSHIPS_FETCHING',
    FETCHING_ERROR: 'PARTNERSHIPS_FETCHING_ERROR',
    FETCHING_SUCCESS: 'PARTNERSHIPS_FETCHING_SUCCESS',
    FETCHING_SUCCESS_SINGLE: 'PARTNERSHIPS_FETCHING_SUCCESS_SINGLE',
    SELECT_PARTNERSHIP: 'PARTNERSHIPS_SELECT_PARTNERSHIPS',
    EMPTY_LIST: 'PARTNERSHIPS_EMPTY_LIST',
  },
  AUTH: {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGIN_CLEAR_ERROR: 'LOGIN_CLEAR_ERROR',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    SET_LANGUAGE: 'SET_LANGUAGE',
    UPDATE_ROLE: 'UPDATE_ROLE',
  },
  GOLF_COURSE: {
    GET_LIST: 'golf_course/GET_LIST',
    GET_FROM_GOLF_CLUB: 'golf_course/GET_FROM_GOLF_CLUB',
    SELECT: 'golf_course/SELECT',
  },
  CLUB: {
    SET_CLUB: 'SET_CLUB',
    SET_CLUB_WEB_BOOKING_SETTINGS: 'SET_CLUB_WEB_BOOKING_SETTINGS',
  },
  COURSES: {
    SET_COURSES: 'SET_COURSES',
    SET_CURRENT_COURSE: 'SET_CURRENT_COURSE',
  },
}
export default CONSTANTS
