import CONSTANTS from '@sweetspot/apps/partner-portal/store/constants'

export const loginRequest = () => {
  return {
    type: CONSTANTS.AUTH.LOGIN_REQUEST,
  }
}

export const loginFailure = (errorMessage) => {
  return {
    type: CONSTANTS.AUTH.LOGIN_FAILURE,
    errorMessage: errorMessage || 'Unknown',
  }
}

export const loginSuccess = (currentUser) => {
  return {
    type: CONSTANTS.AUTH.LOGIN_SUCCESS,
    currentUser,
  }
}

export const updateRole = (newRole) => {
  return {
    type: CONSTANTS.AUTH.UPDATE_ROLE,
    newRole,
  }
}

export const loginClearError = () => {
  return {
    type: CONSTANTS.AUTH.LOGIN_CLEAR_ERROR,
  }
}

export const logoutSuccess = () => {
  return {
    type: CONSTANTS.AUTH.LOGOUT_SUCCESS,
  }
}
