import { ReactComponent as SSLogo } from '@sweetspot/shared/assets/images/logos/logo.svg'

import styles from './styles.module.scss'
import cx from 'classnames'

export interface FullScreenLoadingProps {
  loading: boolean
}

export const FullScreenLoading = (props: FullScreenLoadingProps) => {
  return (
    <div
      className={`bg-sand-100 fixed z-[99] flex h-full w-full flex-col items-center justify-center transition-all ${
        !props.loading ? 'scale-200 pointer-events-none opacity-0' : 'opacity-1 scale-100'
      }`}
    >
      <div className={'w-44 pb-6'}>
        <SSLogo className={'h-auto w-full'} />
      </div>

      <div className={cx(styles.loader, 'border-black ')} />
    </div>
  )
}
