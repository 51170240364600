import React from 'react'

import Spinner from '@sweetspot/sweetspot-js/common/components/Spinner'

import styles from './styles.module.scss'

const LazyLoading = () => {
  return (
    <div className={styles.container}>
      <Spinner color="light" className={styles.spinner} />
    </div>
  )
}

export default LazyLoading
