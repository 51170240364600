import CONSTANTS from '@sweetspot/apps/partner-portal/store/constants'
import initialState from './initialState'
import produce from 'immer'

const app = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.AUTH.LOGIN_REQUEST:
      return produce(state, (draft) => {
        draft.isFetching = true
        draft.errorMessage = null
      })

    case CONSTANTS.AUTH.LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft.isFetching = false
        draft.isAuthenticated = true
        draft.currentUser = action.currentUser
        draft.errorMessage = null
      })

    case CONSTANTS.AUTH.UPDATE_ROLE:
      return produce(state, (draft) => {
        draft.currentUser.role = action.newRole
      })

    case CONSTANTS.AUTH.LOGIN_FAILURE:
      return produce(state, (draft) => {
        draft.isFetching = false
        draft.isAuthenticated = false
        draft.currentUser = null
        draft.errorMessage = action.errorMessage
      })

    case CONSTANTS.AUTH.LOGIN_CLEAR_ERROR:
      return produce(state, (draft) => {
        draft.errorMessage = null
      })

    case CONSTANTS.AUTH.LOGOUT_SUCCESS:
      return produce(state, (draft) => {
        draft.isFetching = false
        draft.isAuthenticated = false
        draft.currentUser = null
        draft.errorMessage = null
      })
    case CONSTANTS.AUTH.SET_LANGUAGE:
      return produce(state, (draft) => {
        draft.currentUser.language = action.language
      })
    default:
      return state
  }
}

export default app
