import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from '@sweetspot/apps/partner-portal/store/reducers'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['partnerships', 'auth', 'club'],
}

export let persistor

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, preloadedState, composedEnhancers)
  persistor = persistStore(store)

  // Add hot-reloading
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return { store, persistor }
}
