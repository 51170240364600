import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Get golf clubs belonging to CA
 *
 * @returns {promise}
 */
export const getCAGolfClubs = (clubAdminId) => {
  const url = `${getBaseUrlPlatform()}/clubs?admins.id=${clubAdminId}`
  return request(url, { method: 'GET' })
}
