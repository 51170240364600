import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import auth from './features/auth/reducer'
import partnerships from './features/partnerships/reducer'
import club from './features/club/reducer'
import courses from './features/courses/reducer'

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['isFetching'],
}

const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  partnerships,
  club,
  courses,
})

export default reducers
