import produce from 'immer'
import { CONSTANTS as REDUX_ACTIONS } from '@sweetspot/apps/partner-portal/store/constants'
import initialState from './initialState'

const courses = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.COURSES.SET_COURSES:
      return produce(state, (newState) => {
        newState.courses = action?.payload || null
      })
    case REDUX_ACTIONS.COURSES.SET_CURRENT_COURSE:
      return produce(state, (newState) => {
        newState.currentCourse = action?.payload || null
      })
    default:
      return state
  }
}

export default courses
