import { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
// import useInterval from '@sweetspot/apps/partner-portal/hooks/useInterval';
import i18n from 'i18next'

import {
  getPartnershipList,
  getCAGolfClubs,
} from '@sweetspot/apps/partner-portal/services/api-platform/features'
import { getMe } from '@sweetspot/shared/data-access/api-platform'

import { getLocalStorage, clearLocalStorage } from '@sweetspot/shared/util/local-storage'

import {
  selectPartnership,
  partnershipsLoadedSuccessfully,
  setClubWebSettings,
} from '@sweetspot/apps/partner-portal/store/actions'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'

import useAuthService from '@sweetspot/apps/partner-portal/hooks/useAuthService'
import { ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import { useTranslation } from 'react-i18next'
import { getFirstMatchingRole } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { getClubWebBookingSetting } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'

const useAppInit = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { attemptLogout, loginToClub } = useAuthService()
  const [appReady, setAppReady] = useState(false)
  const [hasPartnerships, setHasPartnerships] = useState(false)

  const { currentUser } = useSelector((state) => state.auth)
  const { addToast } = useToasts()

  const role = useMemo(() => {
    return getFirstMatchingRole(currentUser?.role)
  }, [currentUser])

  const fetchData = useCallback(async () => {
    let query = ''

    // Check auth
    const [authRes, authErr] = await to(getMe())
    // To solve the issue of cached token
    if (authErr && authErr?.code === 401 && authErr?.message === 'JWT Token not found') {
      addToast(i18n.t('sentences.autoLogout'), { appearance: 'warning' })
      clearLocalStorage()
      attemptLogout()
      return
    }

    if (
      !authRes &&
      authErr &&
      authErr?.message === 'Wrong guard, Admin expected' &&
      authErr?.status === 400
    ) {
      addToast(i18n.t('sentences.autoLogout'), { appearance: 'warning' })
      attemptLogout()
      return
    }

    if (role?.tier === 0) {
      query = 'order[statusPriority]=asc&order[name]=asc'
    } else if (
      role.value === ROLES_DEF.ROLE_PARTNER.value ||
      role.value === ROLES_DEF.ROLE_PARTNER_BOOKER.value
    ) {
      query = `bookers.id=${currentUser.id}&order[statusPriority]=asc&order[name]=asc`
    } else if (role?.tier <= 5) {
      // Fetch golfclubs belonging to CA
      const [res, err] = await to(getCAGolfClubs(currentUser.id))

      if (err || res.length === 0) {
        addToast(i18n.t('errors.noClubCouldBeFound'), { appearance: 'error' })

        setAppReady(true)
        return

        // Add toast
      } else {
        // TO-DO Create a query with array of club id's
        let clubQuery = ''

        for (let i = 0; i < res.length; i++) {
          if (i === 0) clubQuery += `club.id[]=${res[i].id}`
          else clubQuery += `&club.id[]=${res[i].id}`
        }
        query = `${clubQuery}&order[statusPriority]=asc&order[name]=asc`
      }
    }
    const [res, err] = await to(getPartnershipList(query))

    if (err) {
      addToast(i18n.t('errors.couldNotFetchPartnerships'), { appearance: 'error' })
    } else {
      const fetchedPartnerships = res['hydra:member']
      const totalItems = res['hydra:totalItems']
      const itemsPerPage = res['hydra:itemsPerPage']
      const totalPages = Math.ceil(totalItems / itemsPerPage)
      const userHasPartnerships = fetchedPartnerships.length > 0
      const savedPartnershipId = parseInt(getLocalStorage('selectedPartnership'))
      const selectedPartnership = fetchedPartnerships.find(({ id }) => id === savedPartnershipId)

      // Check if partnership id saved in storage belongs to the user, override it with new partnership
      if (userHasPartnerships && !selectedPartnership) {
        const [, err] = await loginToClub(fetchedPartnerships[0].club.uuid)
        if (err) {
          addToast(t('sentences.canNotSelectPartnership'), { appearance: 'error' })
        } else {
          dispatch(selectPartnership(fetchedPartnerships[0].id))
        }
      } else if (selectedPartnership) {
        const [, err] = await loginToClub(selectedPartnership.club.uuid)
        if (err) {
          addToast(t('sentences.canNotSelectPartnership'), { appearance: 'error' })
        } else {
          dispatch(selectPartnership(selectedPartnership.id))
        }
      }

      dispatch(partnershipsLoadedSuccessfully({ partnerships: fetchedPartnerships, totalPages }))

      if (userHasPartnerships) setHasPartnerships(true)

      if (selectedPartnership) {
        const [settingsRes] = await to(getClubWebBookingSetting(selectedPartnership?.club.id))
        if (settingsRes) {
          dispatch(setClubWebSettings(settingsRes))
        }
      }
    }

    setAppReady(true)
    return
  }, [dispatch, setAppReady, setHasPartnerships, role, currentUser?.id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // useInterval(() => fetchData(), 30000);

  return [appReady, hasPartnerships]
}

export default useAppInit
