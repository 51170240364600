import {
  addAdminToPartnership,
  getPartnerAdminsWithPartnership,
  removeAdminsFromPartnership,
} from '@sweetspot/apps/partner-portal/services/api-platform/features'
import { updateClubUser } from '@sweetspot/sweetspot-js/features/clubUsers/services/api-platform'

/**
 * Updates a admins user information
 * @param {int} id of partner
 * @param {string} role
 * @param {Object} payload
 * @param {string} payload.first_name
 * @param {string} payload.last_name
 * @param {string} payload.email
 * @param {string} payload.company
 * @param {string} payload.password
 * @returns {promise}
 */
export const updateAdmin = (id, role, payload) => {
  return new Promise((resolve, reject) => {
    updateClubUser(id, payload)
      .then((data) => resolve(data))
      .catch((err) => {
        // TO-DO: Add toast with error message
        reject(err)
      })
  })
}

/**
 * Updates partner admins user information
 * @param {int} id of partner
 * @param {string} email

 * @returns {promise}
 */
export const addExistingAdminToPartnership = (id, email) => {
  return () => {
    return new Promise((resolve, reject) => {
      addAdminToPartnership(id, email)
        .then((data) => resolve(data))
        .catch((err) => {
          reject(err)
        })
    })
  }
}

/**
 * Removes a partner admin from selected partnership
 * @param {int} id of partner
 * @param {int} id of admin
 * @returns {promise}
 */
export const removeExistingAdminsFromPartnership = (partnership, admins) => {
  const arrayOfAdmins = admins.map((admin) => ({ booker: admin.id }))
  return () => {
    return new Promise((resolve, reject) => {
      removeAdminsFromPartnership(partnership, arrayOfAdmins)
        .then((data) => resolve(data))
        .catch((err) => {
          // TO-DO: Add toast with error message
          reject(err)
        })
    })
  }
}

/**
 * Updates partner admins user information
 * @param {int} id of partnership
 * @returns {promise}
 */
export const getListOfBookers = (id, query) => {
  return () => {
    return new Promise((resolve, reject) => {
      getPartnerAdminsWithPartnership(id, query)
        .then((data) => resolve(data))
        .catch((err) => {
          // TO-DO: Add toast with error message
          reject(err)
        })
    })
  }
}
