import CONSTANTS from '@sweetspot/apps/partner-portal/store/constants'
import initialState from './initialState'
import produce from 'immer'
import { arrayToObject } from '@sweetspot/sweetspot-js/common/functions/utils'
import m from 'moment'

// Follows normalize standard recommended by redux:
// https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape/

const partnerships = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.PARTNERSHIP.FETCHING_SUCCESS:
      return produce(state, (draft) => {
        const { partnerships, totalPages } = action.payload
        draft.byId = partnerships ? arrayToObject(partnerships) : 0
        draft.allIds = partnerships.map((item) => ({
          id: item.id,
          uuid: item.uuid,
          name: item.name,
          club: item.club,
          status: item.status,
          startsAt: new Date(m.utc(item.starts_at).local()),
          endsAt: new Date(m.utc(item.expires_at).local()),
        }))
        draft.partnerships = partnerships
        if (totalPages) draft.totalPages = totalPages
      })

    case CONSTANTS.PARTNERSHIP.FETCHING_SUCCESS_SINGLE:
      return produce(state, (draft) => {
        draft.byId[action.id] = action.payload
        if (!draft.allIds.find((x) => x.id === action.id)) {
          draft.allIds.push(action.id)
        }
      })

    case CONSTANTS.PARTNERSHIP.SELECT_PARTNERSHIP:
      return produce(state, (draft) => {
        draft.selectedId = action.payload
      })

    case CONSTANTS.PARTNERSHIP.EMPTY_LIST:
      return produce(state, (draft) => {
        draft.allIds = []
      })

    default:
      return state
  }
}

export default partnerships
