import { CONSTANTS as REDUX_ACTIONS } from '@sweetspot/apps/partner-portal/store/constants'
import { setLocalStorage } from '@sweetspot/shared/util/local-storage'

export const partnershipsLoadedSuccessfully = (partnerships) => {
  return (dispatch) => {
    dispatch({ type: REDUX_ACTIONS.PARTNERSHIP.FETCHING_SUCCESS, payload: partnerships })
  }
}

export const selectPartnership = (id) => {
  return (dispatch) => {
    setLocalStorage('selectedPartnership', id)
    dispatch({ type: REDUX_ACTIONS.PARTNERSHIP.SELECT_PARTNERSHIP, payload: id })
  }
}

export const emptyPartnershipList = () => {
  return (dispatch) => {
    dispatch({ type: REDUX_ACTIONS.PARTNERSHIP.EMPTY_LIST })
  }
}
