import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import queryString from 'query-string'
import { GetNextAvailableTeeTimeQuery, NextAvailableTeeTime } from './types'

export const getNextAvailableTeeTime = (
  query: GetNextAvailableTeeTimeQuery = {
    'course.uuid': null,
    'from[before]': null,
    'from[after]': null,
    page: 1,
    limit: 50,
  }
): Promise<NextAvailableTeeTime> => {
  const URL = queryString.stringifyUrl({
    url: `${getBaseUrlPlatform()}/tee-times/get-next-available`,
    query,
  })

  return request(URL)
}
