import { IUniversalIdentifier } from '../../../common'
import { Space } from '../space'

export type DynamicPrice = IUniversalIdentifier & {
  amount: number
  currency: string
  price: number
}

type TeeTimeReservationState = {
  status: TeeTimeState
  token: string | null
  uuid: string | null
}

type TeeTimeCategoryDisplay = 'default' | 'full'
type TeeTimeCategory = IUniversalIdentifier & {
  name: string
  color: string
  custom_name: string
  custom_description: string
  display: TeeTimeCategoryDisplay
}

export type Price = {
  amount: number | null
  currency: string | null
}

export type TeeTimePrice = {
  price: Price
  dynamic_price: Price
}

export interface TeeTime extends IUniversalIdentifier {
  created_at: string
  space: Space
  price: number | null
  category: TeeTimeCategory
  updated_at: string
  reservation: TeeTimeReservationState
  from: string
  to: string
  is_prime_time: boolean
  from_unix: number
  to_unix: number
  available_slots: number
  max_slots: number
  regular_price?: number
  price_per_extra_player?: number
  players?: number
}

export interface TeeTimeAvailability extends IUniversalIdentifier {
  is_available: boolean
}

export enum TeeTimeState {
  UNAVAILABLE = 'unavailable',
  BOOKED = 'booked',
  BOOKEDBYME = 'bookedbyme',
  SELECTED = 'selected',
  BOOKABLE = 'bookable',
  NOTBOOKABLE = 'notbookable',
  RESERVED = 'reserved',
}
